import { Maybe } from "../../graphql-types";
import { Moment } from "moment";
import { StorageType } from "./ParticipantFormCell";

export interface ParticipantFormCellData {
  label: string;
  value: Maybe<string | Moment>;
  type: string;
  fieldName: string;
  options?: ParticipantFormCellOptions[];
  storageType?: StorageType;
}

export interface ParticipantFormCellOptions {
  label: string;
  value: string | boolean;
  fieldname: string;
}

export function getParticipantFormDetailsData(fieldNames: any) {
  const options: { [key: string]: ParticipantFormCellOptions[] } = {
    gps_monitoring: [
      {
        label: "Loc8",
        fieldname: "loc8",
        value: false,
      },
      { label: "Beacon", fieldname: "beacon", value: false },
      {
        label: "GPS-Charging",
        fieldname: "gps_charging",
        value: false,
      },
      {
        label: "GPS-Battery",
        fieldname: "gps_monitoring",
        value: false,
      },
    ],
    alcohol_monitoring: [
      {
        label: "TAD-Phone",
        fieldname: "alcohol_monitoring",
        value: false,
      },
      { label: "TAD-HB", fieldname: "alcohol_homebase", value: false },
      {
        label: "TAD-HB-Charging",
        fieldname: "alcohol_charging",
        value: false,
      },
    ],
  };

  const equipmentReturnedOptions = Object.keys(
    fieldNames.monitoring_devices
  ).reduce(
    (acc, key) => {
      if (fieldNames.monitoring_devices[key] === true) {
        acc = [...acc, ...options[key]];
      }
      return acc;
    },
    [] as ParticipantFormCellOptions[]
  );

  return [
    [
      [
        {
          label: "DOB:",
          value: "",
          type: "date",
          fieldName: "date_of_birth",
        },
      ],
      [
        {
          label: "Residence",
          value: "",
          type: "input",
          fieldName: "person_address",
        },
      ],
      [
        {
          label: "Home Phone",
          value: "",
          type: "input",
          fieldName: "home_phone",
        },
      ],
      [
        {
          label: "Cell Phone",
          value: "",
          type: "input",
          fieldName: "mobile",
        },
      ],
    ],
    [
      [
        {
          label: "Pre-Trial Start Date:",
          value: "",
          type: "date",
          fieldName: "supervision_begin_date",
        },
        {
          label: "Projected End Date:",
          value: "",
          type: "date",
          fieldName: "supervision_end_date",
        },
      ],
      [
        {
          label: "First Name:",
          value: "",
          type: "input",
          fieldName: "first",
        },
        {
          label: "Last Name:",
          value: "",
          type: "input",
          fieldName: "last",
        },
        {
          label: "Sex",
          value: "select",
          type: "select",
          fieldName: "sex",
          options: [
            {
              label: "Unknown",
              fieldname: "sex",
              value: "unknown",
            },
            {
              label: "Female",
              fieldname: "sex",
              value: "female",
            },
            {
              label: "Male",
              fieldname: "sex",
              value: "male",
            },
          ],
        },
      ],
      [
        {
          label: "Race",
          value: "",
          type: "select",
          fieldName: "race",
          options: [
            {
              label: "Unknown",
              fieldname: "race",
              value: "Unknown",
            },
            {
              label: "Asian",
              fieldname: "race",
              value: "Asian",
            },
            {
              label: "Am. Indian Or Alaskan Native",
              fieldname: "race",
              value: "AmericanIndianOrAlaskaNative",
            },
            {
              label: "Black",
              fieldname: "race",
              value: "BlackOrAfricanAmerican",
            },
            {
              label: "Hawaiian or Other Pacific Islander",
              fieldname: "race",
              value: "NativeHawaiianOrOtherPacificIslander",
            },
            {
              label: "White",
              fieldname: "race",
              value: "White",
            },
          ],
        },
        {
          label: "Hispanic or Latino Heritage",
          value: "",
          type: "select",
          fieldName: "hispanic_or_latino",
          options: [
            {
              label: "No",
              fieldname: "hispanic_or_latino",
              value: false,
            },
            {
              label: "Yes",
              fieldname: "hispanic_or_latino",
              value: true,
            },
          ],
        },
      ],
      [
        {
          label: "Devices: Alcohol / GPS",
          value: "",
          type: "checkbox",
          fieldName: "monitoring_devices",
          options: [
            { label: "Alcohol", fieldname: "alcohol_monitoring", value: false },
            { label: "GPS", fieldname: "gps_monitoring", value: false },
          ],
        },
      ],
      [
        {
          label: "Equipment Returned",
          value: "",
          type: "checkbox",
          fieldName: "equipment_returned",
          storageType: StorageType.Array,
          options: equipmentReturnedOptions,
        },
      ],
      [
        {
          label: "Offense Info:",
          value: "",
          type: "input",
          fieldName: "offense_info",
        },
      ],
      [
        {
          label: "Eyes:",
          value: "",
          type: "select",
          fieldName: "eye_color",
          options: [
            {
              fieldname: "hair",
              value: "unknown",
              label: "Unknown",
            },
            {
              fieldname: "eye_color",
              value: "black",
              label: "Black",
            },
            {
              fieldname: "eye_color",
              value: "blue",
              label: "Blue",
            },
            {
              fieldname: "eye_color",
              value: "brown",
              label: "Brown",
            },
            {
              fieldname: "eye_color",
              value: "gray",
              label: "Gray",
            },
            {
              fieldname: "eye_color",
              value: "green",
              label: "Green",
            },
            {
              fieldname: "eye_color",
              value: "hazel",
              label: "Hazel",
            },
            {
              fieldname: "eye_color",
              value: "maroon",
              label: "Maroon",
            },
          ],
        },
        {
          label: "Hair:",
          value: "",
          type: "select",
          fieldName: "hair",
          options: [
            {
              fieldname: "hair",
              value: "unknown",
              label: "Unknown",
            },
            {
              fieldname: "hair",
              value: "bald",
              label: "Bald",
            },
            {
              fieldname: "hair",
              value: "black",
              label: "Black",
            },
            {
              fieldname: "hair",
              value: "blonde",
              label: "Blonde",
            },
            {
              fieldname: "hair",
              value: "blue",
              label: "Blue",
            },
            {
              fieldname: "hair",
              value: "brown",
              label: "Brown",
            },
            {
              fieldname: "hair",
              value: "green",
              label: "Green",
            },
            {
              fieldname: "hair",
              value: "gray",
              label: "Gray",
            },
            {
              fieldname: "hair",
              value: "orange",
              label: "Orange",
            },
            {
              fieldname: "hair",
              value: "purple",
              label: "Purple",
            },
            {
              fieldname: "hair",
              value: "pink",
              label: "Pink",
            },
            {
              fieldname: "hair",
              value: "red",
              label: "Red",
            },
            {
              fieldname: "hair",
              value: "sandy",
              label: "Sandy",
            },
            {
              fieldname: "hair",
              value: "white",
              label: "White",
            },
          ],
        },
      ],
      [
        {
          label: "Height:",
          value: "",
          type: "input",
          fieldName: "height",
        },
        {
          label: "Weight:",
          value: "",
          type: "input",
          fieldName: "weight",
        },
      ],
      [
        {
          label: "Monitoring Level",
          value: "select",
          type: "select",
          fieldName: "risk_level",
          options: [
            {
              label: "Level I",
              fieldname: "risk_level",
              value: "one",
            },
            {
              label: "Level II",
              fieldname: "risk_level",
              value: "two",
            },
            {
              label: "Level III",
              fieldname: "risk_level",
              value: "three",
            },
            {
              label: "Level IV",
              fieldname: "risk_level",
              value: "four",
            },
            {
              label: "Level V",
              fieldname: "risk_level",
              value: "five",
            },
            {
              label: "Level VI",
              fieldname: "risk_level",
              value: "six",
            },
          ],
        },
        {
          label: "VPRAI Score",
          value: "select",
          type: "select",
          fieldName: "assessment_score",
          options: [
            {
              label: "0",
              fieldname: "assessment_score",
              value: "0",
            },
            {
              label: "1",
              fieldname: "assessment_score",
              value: "1",
            },
            {
              label: "2",
              fieldname: "assessment_score",
              value: "2",
            },
            {
              label: "3",
              fieldname: "assessment_score",
              value: "3",
            },
            {
              label: "4",
              fieldname: "assessment_score",
              value: "4",
            },
            {
              label: "5",
              fieldname: "assessment_score",
              value: "5",
            },
            {
              label: "6",
              fieldname: "assessment_score",
              value: "6",
            },
            {
              label: "7",
              fieldname: "assessment_score",
              value: "7",
            },
            {
              label: "8",
              fieldname: "assessment_score",
              value: "8",
            },
            {
              label: "9",
              fieldname: "assessment_score",
              value: "9",
            },
            {
              label: "10",
              fieldname: "assessment_score",
              value: "10",
            },
            {
              label: "11",
              fieldname: "assessment_score",
              value: "11",
            },
            {
              label: "12",
              fieldname: "assessment_score",
              value: "12",
            },
            {
              label: "13",
              fieldname: "assessment_score",
              value: "13",
            },
            {
              label: "14",
              fieldname: "assessment_score",
              value: "14",
            },
          ],
        },
      ],
    ],
  ];
}

export function hydrateParticipantFormDetailsData(
  participant: {
    [key: string]: Maybe<string | Moment | { [key: string]: any } | boolean>;
  },
  participantFormDetailsData: ParticipantFormCellData[][][]
) {
  return participantFormDetailsData.map((participantFormColumn) => {
    return participantFormColumn.map((participantFormRow) => {
      return participantFormRow.map((participantFormCell) => {
        const { fieldName, value, type, options } = participantFormCell;

        if (type === "checkbox") {
          return {
            ...participantFormCell,
            options: options
              ? options.map((option) => {
                  const { fieldname } = option;
                  const optionValueMap = participant[fieldName] as {
                    [key: string]: any;
                  };
                  return {
                    ...option,
                    value:
                      optionValueMap && optionValueMap[fieldname]
                        ? optionValueMap[fieldname]
                        : option.value,
                  } as ParticipantFormCellOptions;
                })
              : undefined,
          };
        } else {
          return {
            ...participantFormCell,
            value: participant[fieldName] ? participant[fieldName] : value,
          } as ParticipantFormCellData;
        }
      });
    });
  });
}
