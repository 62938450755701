import * as React from "react";
import { Redirect } from "@reach/router";

import {
  CaseManager,
  KeyedString,
  PersonName,
} from "../../../../shared/src/lib/graphql/";
import {
  LinkedTabs,
  LinkedTab,
} from "../../shared/components/elements/LinkedTabs";
import {
  ParticipantListTableContainer,
  ParticipantListTableQueryTypes,
} from "../../shared/components/participantListTable";
import { PageHeader } from "../../shared/components/elements/PageHeader";
import AddParticipantQueryWrapper from "../modals/participants/AddParticipantQueryWrapper";
import { CarePlanParticipantType } from "../care-plan/CarePlanQuery";
import { getFeatureFlags } from "../../featureFlags";
import { Operation } from "../modals/types";
import { urlBuilderFn } from "./ParticipantDetailsTabs";
import { ParticipantCount } from "./ParticipantCount";
import { LinkedNameBuilder } from "../elements/LinkedName";

export interface ParticipantListContentsProps {
  baseUrl: string;
  caseManager?: CaseManager;
  titleTag?: React.ReactNode;
  Link: React.ComponentClass<any, any>;
  riskLevels: KeyedString[];
  selectedRiskLevel?: KeyedString;
  tabs: LinkedTab[];
  showActive: boolean;
}

interface ParticipantListContentsState {
  addedParticipantId?: string;
  addedParticipantName?: PersonName;
}

const {
  showTasksInClientTable,
  showEventsInClientTable,
  showProgramTitleInClientTable,
  showRiskLevelInClientTable,
  showSmsStatusInClientTable,
  showStartDateInClientTable,
  showAddressInClientTable,
  supportsCarePlanEditing,
  supportsProgramCompliance,
  clientListPageName,
  clientDisplayTerm,
  whatIsComplianceCalled,
  whatIsRiskLevelCalled,
  sortClientListByNextEvent,
  showHasAppInClientTable,
  showDataUpdateNeededInClientTable,
  showCaseNumberInClientTable,
  showEndDateInClientTable,
  includeCheckInEventsInNextEventDisplay,
} = getFeatureFlags();

let nextEvents = sortClientListByNextEvent
  ? "next-events-sync"
  : !includeCheckInEventsInNextEventDisplay
  ? "next-events"
  : "next-events-include-check-in";

let nextEventsDates = sortClientListByNextEvent
  ? "next-events-dates-sync"
  : !includeCheckInEventsInNextEventDisplay
  ? "next-events-dates"
  : "next-events-include-check-in-dates";

let name = sortClientListByNextEvent ? "name-sorted-by-events" : "name";
let columnsPossibleToDisplay = showTasksInClientTable
  ? [
      name,
      "address",
      "sent_message_unread_count",
      "tasks",
      "start_date",
      "end_date",
      nextEvents,
      nextEventsDates,
      "case_manager",
      "deactivated_reason",
    ]
  : [
      name,
      "address",
      "sent_message_unread_count",
      "start_date",
      "end_date",
      "events",
      nextEvents,
      nextEventsDates,
      "case_manager",
      "deactivated_reason",
    ];

if (showCaseNumberInClientTable) {
  columnsPossibleToDisplay = [...columnsPossibleToDisplay, "case_numbers"];
}

if (supportsProgramCompliance) {
  columnsPossibleToDisplay = [
    ...columnsPossibleToDisplay.slice(0, 2),
    "compliance",
    ...columnsPossibleToDisplay.slice(2),
  ];
}

if (showDataUpdateNeededInClientTable === true) {
  columnsPossibleToDisplay = [
    ...columnsPossibleToDisplay,
    "data_update_needed",
  ];
}

if (showEventsInClientTable === false) {
  columnsPossibleToDisplay = columnsPossibleToDisplay.filter(
    (col) =>
      !col.startsWith(nextEvents) &&
      !col.startsWith("last-events") &&
      col !== "events"
  );
}

if (showStartDateInClientTable === false) {
  columnsPossibleToDisplay = columnsPossibleToDisplay.filter(
    (col) => col !== "start_date"
  );
}

if (!showEndDateInClientTable) {
  columnsPossibleToDisplay = columnsPossibleToDisplay.filter(
    (col) => col !== "end_date"
  );
}

if (showAddressInClientTable === false) {
  columnsPossibleToDisplay = columnsPossibleToDisplay.filter(
    (col) => col !== "address"
  );
}

if (showProgramTitleInClientTable === true) {
  columnsPossibleToDisplay = [...columnsPossibleToDisplay, "program_title"];
}

if (showRiskLevelInClientTable === true) {
  columnsPossibleToDisplay = [...columnsPossibleToDisplay, "risk_level"];
}

if (showSmsStatusInClientTable === true) {
  columnsPossibleToDisplay = [...columnsPossibleToDisplay, "sms_status"];
}

if (showHasAppInClientTable === true) {
  columnsPossibleToDisplay = [...columnsPossibleToDisplay, "current_device"];
}

export class ParticipantListContents extends React.PureComponent<
  ParticipantListContentsProps,
  ParticipantListContentsState
> {
  state = {} as ParticipantListContentsState;

  private participantAdded = ({
    id: addedParticipantId,
    name: addedParticipantName,
  }: CarePlanParticipantType) => {
    this.setState({ addedParticipantId, addedParticipantName });
  };

  render() {
    const {
      baseUrl,
      caseManager,
      Link,
      selectedRiskLevel,
      tabs,
      showActive,
    } = this.props;
    const { addedParticipantId, addedParticipantName } = this.state;

    const urlBuilder = urlBuilderFn(baseUrl);

    if (addedParticipantId) {
      const redirectUrl = urlBuilder({
        id: addedParticipantId,
        name: addedParticipantName || ({} as any),
      }).default;
      return <Redirect to={redirectUrl} noThrow />;
    }

    const columnsToDisplayCaseManager = caseManager
      ? columnsPossibleToDisplay.filter((x) => x !== "case_manager")
      : columnsPossibleToDisplay.filter((x) => x !== "risk_level");

    const columnsToDisplayActive = showActive
      ? columnsToDisplayCaseManager.filter((x) => x !== "deactivated_reason")
      : columnsToDisplayCaseManager.filter((x) => x !== "compliance");

    return (
      <div className="participant">
        <PageHeader
          pageName={caseManager ? "Caseload" : clientListPageName}
          turnOffBottomPadding={
            !(!tabs || !Array.isArray(tabs) || tabs.length === 0) // taken from LinkedTabs.ts
          }
          headerRight={
            <>
              {supportsCarePlanEditing ? (
                <AddParticipantQueryWrapper
                  callback={this.participantAdded}
                  operation={Operation.Add}
                  type={clientDisplayTerm}
                />
              ) : null}
            </>
          }
          titleTag={
            <>
              {this.props.titleTag}
              <ParticipantCount
                cmId={caseManager ? caseManager.id : ""}
                showActive={
                  caseManager && caseManager.id ? showActive : undefined
                }
              />
            </>
          }
        >
          <LinkedTabs
            tabs={tabs}
            LinkComponent={Link}
            tabProps={{ type: "boxed", className: "risk-tabs" }}
          />
        </PageHeader>
        <div className="constrained-section activeContentContainer">
          <ParticipantListTableContainer
            showSecondaryCaseManager={true}
            tableQuery={
              sortClientListByNextEvent
                ? ParticipantListTableQueryTypes.ParticipantListSortedByEvent
                : ParticipantListTableQueryTypes.ParticipantList
            }
            showActive={showActive}
            caseManager={this.props.caseManager}
            columnsToDisplay={columnsToDisplayActive}
            supportsProgramCompliance={supportsProgramCompliance}
            clientDisplayTerm={clientDisplayTerm}
            nameComponent={LinkedNameBuilder(urlBuilderFn)({ baseUrl, Link })}
            riskLevel={selectedRiskLevel}
            whatIsComplianceCalled={whatIsComplianceCalled}
            whatIsRiskLevelCalled={whatIsRiskLevelCalled}
            urlBuilder={urlBuilder}
          />
        </div>
      </div>
    );
  }
}
